import { Field, Form, Formik } from "formik";
import { Link, navigate } from "gatsby";
import React from "react";
import { useDispatch } from "react-redux";
import { Input } from "../../components/InputFields";
import { FormLayout } from "../../components/Layout";
import { addMessage } from "../../state/reducers/appReducer";
import { loginAction, requires2faAction } from "../../state/reducers/authReducer";
import { fetchBaseOptions } from "../../utils/fetch";
import { LoginSchema } from "../../utils/validators";

const LoginPage = ({ location }: { location: { state: { next: string } } }) => {
  const dispatch = useDispatch();
  return (
    <FormLayout seo={{ title: "Login" }}>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={LoginSchema}
        onSubmit={(values, { setSubmitting }) => {
          fetch("/api/login", {
            ...fetchBaseOptions,
            body: JSON.stringify(values),
          })
            .then(async res => {
              const j: IAPIResponse = await res.json();
              if (res.ok) {
                dispatch(loginAction());
                if (location.state.next) {
                  navigate(location.state.next);
                } else {
                  navigate("/app");
                }
              } else if (res.status === 403) {
                dispatch(requires2faAction(j.token));
                if (location.state.next) {
                  navigate("/login/otp", { state: { next: location.state.next } });
                } else {
                  navigate("/login/otp");
                }
              } else {
                dispatch(addMessage(j));
              }
            })
            .catch(err => console.error(err))
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form className="max-w-md mx-auto space-y-2">
            <Field required={true} type="email" name="email" label="Email" component={Input} />
            <Field
              required={true}
              type="password"
              name="password"
              label="Password"
              component={Input}
            />
            <button
              className="w-full btn btn-primary"
              type="submit"
              disabled={!isValid || isSubmitting}
            >
              Submit
            </button>
          </Form>
        )}
      </Formik>
      <p className="pt-4">
        Don't have an account yet? <Link to="/signup">Sign up!</Link>
      </p>
      <small className="block pt-2">
        <Link to="/account/password/forgot">Forgot your password?</Link>
      </small>
      <small className="block pt-2">
        <Link className="text-black" to="/account/request-activation">
          Request new activation link
        </Link>
      </small>
    </FormLayout>
  );
};

export default LoginPage;
